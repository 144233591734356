import React from 'react';
import Header from '../Header/Header';
import './Hero.css';
import hero_image from '../../assets/hero_image.png';
import hero_image_back from '../../assets/hero_image_back.png';
import Heart from '../../assets/heart.png';
import Calories from '../../assets/calories.png';

// Library for animation on numbers
import NumberCounter from "number-counter";

const Hero = () => {
  return (
    <div className="hero">

        <div className="blur hero-blur">
            
        </div>

        <div className="left-h">
            <Header />

            <div className="the-best-ad">
                <div></div>
                <span>The Best Fitness Club in Town</span>
            </div>

            <div className="hero-text">
                <div>
                    <span className="stroke-text">Shape </span>
                    <span>Your</span>
                </div>
                <div>
                    <span>Ideal Body</span>
                </div>
                <div>
                    <span>
                        We help you shape, build your ideal body and live up your life to the fullest
                    </span>
                </div>
            </div>

            <div className="figures">
                <div>
                    <span>
                        <NumberCounter end={140} start={100} delay='3' preFix='+' />
                    </span>
                    <span>expert coaches</span>
                </div>
                <div>
                    <span>
                    <NumberCounter end={978} start={800} delay='3' preFix="+" />
                    </span>
                    <span>members joined</span>
                </div>
                <div>
                    <span>
                    <NumberCounter end={50} start={30} delay='3' preFix="+" />
                    </span>
                    <span>fitness programs</span>
                </div>
            </div>

            <div className="hero-buttons">
                <button className="btn">Get Started</button>
                <button className="btn">Learn More</button>
            </div>
        </div>

        <div className="right-h">
            <button className='btn'>Join Now</button>

            <div className="heart-rate">
                <img src={Heart} alt='Heart' />
                <span>Heart Rate</span>
                <span>116 bpm</span>
            </div>

            <img src={hero_image} alt='Hero' className='hero-image' />
            <img src={hero_image_back} alt='Hero 2nd' className='hero-image-back' />

            <div className="calories">
                <img src={Calories} alt='Calories' />
                <div>
                    <span>Calories Burned</span>
                    <span>1200 cal</span>
                </div>
            </div>
        </div>
    </div>
  )
}

export default Hero;
