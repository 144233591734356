import image1 from "../assets/t-image1.png";
import image2 from "../assets/t-image2.jpg";
import image3 from "../assets/t-image3.jpg";

export const testimonialsData = [
  {
    image: image1,
    review:
      "I made the right choice by choosing Fitclub and by choosing the right plan and program I have already achieved my ideal body!",
    name: 'MATHEW HENDRICKSON',
    status : 'ENTREPRENEUR'
  },
  {
    image: image2,
    review: 'They have excellent fitness trainers. Their knowledge about maintaining good health is really helping out a lot to stay fit.',
    name: 'JOHN KEVIN',
    status: 'LECTURER'
  },
  {
    image : image3,
    review:'I used to do my routine workout every day evening at the GYM. The equipment\'s were excellent and the Trainer was very friendly and guided me during the workout.',
    name: 'FRANKLIN',
    status: "DESIGNER"
  }
];
