import React from 'react';
import './Footer.css';
import Github from '../../assets/github.png';
import LinkedIn from '../../assets/linkedin.png';
import Logo from '../../assets/logo.png';

const Footer = () => {
  return (
    <div className="Footer-container">
        <hr />

        <div className="footer">
            <div className="social-links">
                <a href="https://github.com/Siddhant-Rawat">
                    <img src={Github} alt="Github" />
                </a>
                <a href="https://www.linkedin.com/in/siddhant-rawat/">
                    <img src={LinkedIn} alt="LinkedIn" />
                </a>
            </div>

            <div className="logo-f">
                <img src={Logo} alt="Logo" />
            </div>
        </div>

        <div className="blur blur-1">

        </div>
        <div className="blur blur-2">

        </div>
    </div>
  );
}

export default Footer;
